@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/eot/iranyekanwebboldfanum.eot');
  src: url('../fonts/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebboldfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebboldfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/eot/iranyekanwebthinfanum.eot');
  src: url('../fonts/eot/iranyekanwebthinfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebthinfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebthinfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/eot/iranyekanweblightfanum.eot');
  src: url('../fonts/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanweblightfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanweblightfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/eot/iranyekanwebregularfanum.eot');
  src: url('../fonts/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebregularfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebregularfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/eot/iranyekanwebmediumfanum.eot');
  src: url('../fonts/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebmediumfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/eot/iranyekanwebextraboldfanum.eot');
  src: url('../fonts/eot/iranyekanwebextraboldfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebextraboldfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebextraboldfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/eot/iranyekanwebblackfanum.eot');
  src: url('../fonts/eot/iranyekanwebblackfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebblackfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebblackfanum.ttf') format('truetype');
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 950;
  src: url('../fonts/eot/iranyekanwebextrablackfanum.eot');
  src: url('../fonts/eot/iranyekanwebextrablackfanum.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/woff/iranyekanwebextrablackfanum.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/ttf/iranyekanwebextrablackfanum.ttf') format('truetype');
}

* {
  -webkit-font-smoothing: antialiased;
}

body {
  direction: rtl;
  text-align: right;
  background: #F4F4F9;
}

.app-main-content {
  background: #f4f4f978
}

.app-cover {
  height: 220px;
  width: 100%;
  background: url(../bg.jpg) no-repeat top center;
  background-size: cover;
  z-index: -1;
  position: absolute;
}

.app-logo {
  height: 47px;
}

.app-caption {
  font-weight: 500;
  font-size: 16px;
}

.app-block {
  background: #FFFFFF;
  box-shadow: 0 20px 40px 0 rgba(36,36,55,0.05);
  border-radius: 10px;
}

.app-nav {
  font-size: 15px;
}

.app-main {
  input[type=text] {
    height: 55px;
    font-size: 16px;
  }
  .btn-purple {
    background-image: linear-gradient(124deg, #6146C0 0%, #9059C7 100%);
    border-radius: 5px;
    border: none;
  }
  h2 {
    font-size: 16px;
    color: #484C6D;
  }
}

.app-footer {
  color: #535565;
}

.app-notification {
  background: #eee8ff;
  color: #b76aef;
  font-size: 15px;
  line-height: 32px;
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 20;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ab77de;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

h1 {
  font-size: 16px;
  font-weight: 800;
}

.ant-table-thead > tr > th {
  border-bottom: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #2B2B42;
  font-size: 12px;
  font-weight: 600;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ant-table-tbody > tr.ant-table-row:hover > td { background: #fefefe }

.ant-table-tbody {
  //color: #7c7c8a;
  font-size: 15px;
  &:before {
    content:"@";
    display:block;
    line-height:10px;
    text-indent:-99999px;
  }
}

.app-actions {
  font-size: 15px;
}

.ant-form-rtl .ant-form-item-label {
  text-align: right;
}

.bg-purple-custom {
  background: rgba(245, 243, 255, 0.65)
}

.ant-switch-checked {
  background: linear-gradient(
                  45deg
          , #33208c, #ba65ed);
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-close {
  margin-top: 15px;
  margin-left: -6px;
}

.ant-slider-track {
  background: linear-gradient(
                  45deg
          , #33208c, #ba65ed);
}

.ant-slider-handle-2 {
  border-color: #33208c;
}

.ant-slider-handle-1 {
  border-color: #ba65ed;
}

.console-mode {
  .ant-modal-content {
    background: black !important;
    color: white;
    font-family: 'Inconsolata', monospace;
  }
  .ant-modal-close-x svg {
    fill: white !important;
  }

  .ant-modal-close {
    left: unset;
    right: 20px;
  }

  .custom-scroll {
    direction: ltr;

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #000000;
    }

    &::-webkit-scrollbar
    {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #F5F5F5;
      border-radius: 6px;
    }
  }

}

.ant-table-container {
  overflow: auto;
  width: 100%;
}

.ant-modal-mask {
  padding: 40px;
}